import React from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Icon from '../Icon';
import BackIcon from '../../public/static/images/item/arrow-right.svg';
import { BackHistoryStorage } from '../../utils/helpers/BackHistoryStorage';

const BackButton = ({
  icon = BackIcon,
  className,
  width = 10,
  height = 16
}) => {
  const router = useRouter();

  const isShowBackButton = BackHistoryStorage.getBackHistoryStorage();

  if (!isShowBackButton) return null;

  const handleHistoryBack = () => {
    router.back();
    router.beforePopState(({ url }) => {
      if (url.includes('#')) {
        window.history.go(-2);
        return false;
      }
      return true;
    });
  };

  return (
    <div
      className={cn('back-button', className)}
      data-cy="back-button"
      role="button"
      tabIndex={0}
      onKeyPress={handleHistoryBack}
      onClick={handleHistoryBack}
    >
      <i className="back-button__icon">
        <Icon
          indent={false}
          IconComponent={icon}
          width={width}
          height={height}
        />
      </i>
    </div>
  );
};

BackButton.propTypes = {
  icon: PropTypes.func,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number
};

export default BackButton;
