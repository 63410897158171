import config from '../../config';

export const hostname = (lang, host) => {
  switch (lang) {
    case 'ru':
      return `${lang}.${host}`;
    default:
      return host;
  }
};

export const hostnameWithoutLang = host => {
  const { languages } = config;
  const lang = host.split('.')[0];
  let pureHost = host;
  if (languages.includes(lang)) {
    pureHost = host.split('.').splice(1).join('.');
  }
  return pureHost;
};

export const makeUrlWithLang = ({ protocol, host, path, lang }) => {
  const defaultLang = 'az';
  const language = lang === defaultLang ? '' : `${lang}.`;
  return `${protocol}://${language}${hostnameWithoutLang(host)}${path}`;
};

export const ensureProtocolInWebsiteUrl = url => {
  const regex = /^https?:\/\//i;
  return regex.test(url) ? url : `//${url}`;
};

export const ensureHTTPProtocolInWebsiteUrl = url => {
  const regex = /^https?:\/\//i;
  return regex.test(url) ? url : `https://${url}`;
};

export const makeHostSum = ({ asPath, req }) => {
  let host;
  let protocol;
  let path;
  if (req) {
    const reqHost =
      req.headers['x-real-host'] || req.headers['x-forwarded-host'];
    const lang = reqHost.includes('ru.') ? 'ru' : 'az';
    host = reqHost.includes('react.bina.az')
      ? hostname(lang, config.host)
      : reqHost;
    protocol = req.headers['x-forwarded-proto'] || 'https';
    path = req.url;
  } else {
    const { host: locationHost } = window.location;
    host = locationHost;
    protocol = window.location.protocol.slice(0, -1);
    path = asPath;
  }

  const currentUrl = `${protocol}://${host}${path}`;
  return {
    currentUrl,
    protocol,
    host,
    path
  };
};

export const normalizePath = asPath => {
  const isParamsExist = asPath.indexOf('?') !== -1;
  return asPath.substring(
    0,
    isParamsExist ? asPath.indexOf('?') : asPath.length
  );
};

export const normalizeWebsiteUrl = url => {
  if (!url) return;

  const { socialNetworksArr } = config;
  const m = url.match(/^(?:https?:\/\/)?(?:www\.)?([0-9a-z-]+\.[0-9a-z\-.]+)/i);
  const sn = socialNetworksArr.find(s => m[1] === `${s.toLowerCase()}.com`);

  return sn || m[1];
};

export const setLinkClass = (value, defaultClass) => {
  const { socialNetworksArr } = config;

  return socialNetworksArr.includes(value)
    ? `${defaultClass}--${value.toLowerCase()}`
    : '';
};
export const getAuthPath = redirectTo => {
  const { authAppUrl } = config;
  return `${authAppUrl}/?return_to=${redirectTo}`;
};

export const createAuthUrl = () => {
  const returnToUrl = btoa(window.location.href);
  return getAuthPath(returnToUrl);
};

export const createAuthUrlByNumber = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const existingReturnTo = urlParams.get('return_to');

  if (existingReturnTo && existingReturnTo !== 'undefined') {
    const decodedReturnTo = atob(existingReturnTo);
    const absoluteUrl = new URL(decodedReturnTo, window.location.origin);
    return getAuthPath(btoa(absoluteUrl.href));
  }

  return getAuthPath(btoa(`${window.location.origin}/`));
};

export const getReturnLinkAfterEmailAuth = currentRoute => {
  return btoa(currentRoute);
};

export const decodeReturnToUrl = returnTo => {
  if (returnTo) {
    return atob(returnTo);
  }

  return null;
};
