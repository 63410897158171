import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Icon = ({
  IconComponent,
  width = 15,
  height = 15,
  color = 'blue',
  indent = true,
  lgIndent = false,
  pathColor,
  iconNeedColorChange = false,
  onClick,
  dataCypress
}) => {
  const iconClasses = cn('svg-icon', `svg-icon--${color}`, {
    [`svg-icon--path-${pathColor}`]: pathColor,
    'svg-icon--indent': indent,
    'svg-icon--indent--large': lgIndent,
    'svg-icon--icon-color-change': iconNeedColorChange
  });

  if (!IconComponent) return null;

  const widthProp = width ? { width } : {};
  const heightProp = height ? { height } : {};
  const props = {
    ...widthProp,
    ...heightProp,
    className: iconClasses,
    onClick
  };

  return <IconComponent {...props} data-cy={dataCypress} />;
};

Icon.propTypes = {
  IconComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  width: PropTypes.number,
  height: PropTypes.number,
  color: PropTypes.string,
  indent: PropTypes.bool,
  lgIndent: PropTypes.bool,
  pathColor: PropTypes.string,
  dataCypress: PropTypes.string,
  iconNeedColorChange: PropTypes.bool,
  onClick: PropTypes.func
};

export default Icon;
